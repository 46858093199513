import React, { useCallback } from 'react';
// import * as d3 from "d3";
import { EntanglementGraph } from "../components/entanglement.js"
// import "../components/about.css"
// import "../components/portfolio.css"
import "../components/entanglementgraph.css"
// import './App.css';
import { Helmet } from "react-helmet";
// import history from "../components/withAddToHistory"
import { Link } from "gatsby"

    const isPartiallyActive = ({
      isPartiallyCurrent
    }) => {
      return isPartiallyCurrent
        // ? { className: "active-header"}
        ? { style: {color: `#000`}}
        : {}
    }

const ListLink = props => (
  <li className="header-link"><Link getProps={isPartiallyActive} to={props.to}>{props.children}</Link>
  </li>
)


const AddGraph = ({data}) => {

  var links = []
    var temporary = []
    
    const buildGraphElements = (data) => {
    // console.log(data);
    data.forEach(({ title, slug, inboundReferences, outboundReferences }) => {
        temporary.push({ id: slug, label: title, size: inboundReferences.length + outboundReferences.length });
    
        const references = []
          .concat(inboundReferences, outboundReferences)
          .filter(Boolean);
    
        references.forEach((reference, refIndex) => {
          links.push({
              source: slug,
              target: reference,
          });
        });
      });

      return temporary;
    }

        const nodes = buildGraphElements(data.brain.nodes);
        // console.log("nodes = ", nodes, " links = ", links)
  const nodeHoverTooltip = useCallback((node) => {
   // console.log(node.srcElement, "was hovered")
    //console.log(node, "was hovered")
    // node.srcElement.style.fill = "red";
    return `<div>${node.srcElement.__data__.label}</div>`;
  }, []);

  return (

    <div>
    <Helmet>
      <title>
        [Working Title]
      </title>
    </Helmet>
    <div>
      <section className="Main">            
             <h2>[WORKING TITLE]</h2>
             <ul className="header-list">

            <ListLink to="/notes/all-pages"> 
            <svg width="20" height="20" viewBox="-2 -5 20 20"><path fillRule="evenodd" clipRule="evenodd" d="M2 3C2 1.89543 2.89543 1 4 1H10L14 5V13C14 14.1046 13.1046 15 12 15H4C2.89543 15 2 14.1046 2 13V3ZM9 3H4V13H12V6H9V3Z"></path><rect x="5" y="7" width="5" height="2"></rect><rect x="5" y="10" width="6" height="2"></rect></svg>
            </ListLink>
            
            <ListLink to="/notes/"> 
            <svg width="20" height="20" viewBox="-2 -5 20 20" fill="currentColor" fillRule="evenodd" clipRule="evenodd"><path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"></path><path d="M8.00002 5C7.23823 5 6.75235 5.42428 6.33207 6.0547L4.66797 4.9453C5.24769 4.07572 6.26181 3 8.00002 3C8.85764 3 9.74527 3.22801 10.4006 3.82095C11.0961 4.45014 11.3871 5.36121 11.24 6.39142C11.0654 7.61316 10.1329 8.20968 9.62788 8.53274C9.61312 8.54219 9.59872 8.5514 9.58471 8.56038C9.28352 8.75365 9.13447 8.85248 9.03752 8.95193C9.01576 8.97425 9.00487 8.98888 9.00006 8.9961C9.00004 8.99719 9.00002 8.99848 9.00002 9V10H7.00002V9C7.00002 8.36831 7.27443 7.89539 7.60537 7.55588C7.87516 7.27912 8.21507 7.06207 8.45012 6.91197C8.46899 6.89992 8.48719 6.8883 8.50461 6.87712C9.08301 6.50598 9.22542 6.35112 9.26007 6.10858C9.32718 5.63879 9.19239 5.42486 9.05886 5.30405C8.88525 5.14699 8.54074 5 8.00002 5Z"></path><circle cx="8" cy="12" r="1.25"></circle></svg> </ListLink>

            {/*   
            <li className="header-link"><a style={{width: 20, height: 20}}></a></li>

            <li className="header-link"><a style={{width: 20, height: 20}}></a></li> */}
            </ul>
            {/* <iframe src="/notes/index"></iframe> */}

        {/* <div className="popOver">
              <h2>What am I looking at?</h2>
              <p> </p>
        </div> */}
        <EntanglementGraph linksData={links} nodesData={nodes} nodeHoverTooltip={nodeHoverTooltip} />
      </section>
    </div>

    <div className="bottom">
      © Malavika Srinivasan, 2023.
    </div>
    </div>
  );
}

export const query = graphql`
  query thesisGraph {
    brain: allBrainNote {
      nodes {
        title
        slug
        inboundReferences
        outboundReferences
      }
    }
  }
`;

export default AddGraph;