//Graph Overview Generator

import * as d3 from "d3";
import styles from "./entanglement.module.css";

let history; 
if (typeof window !== `undefined` && window){
  console.log("in entanglementGenerator, window is defined")
  history = window.sessionStorage.history;
}

export function RunForceGraph(
  container,
  linksData,
  nodesData,
  nodeHoverTooltip,
) {
  const links = linksData.map((d) => Object.assign({}, d));
  const nodes = nodesData.map((d) => Object.assign({}, d));

  const containerRect = container.getBoundingClientRect();
  const height = containerRect.height;
  const width = containerRect.width;
  console.log("local storage", history)

  //color definitions
  const white = "#FFFFFF", 
        yellow = "#FEB561", 
        //maroon = "#4A2632", 
        greige = "#A08986", 
        // beige = "#F6F5F1", 
        charcoal = "#212121";

  const nodeColor = charcoal, linkColor = "rgb(0, 0, 0, 0)"; 
  const nodeHoverColor = charcoal, linkHoverColor = yellow + "80", visitedColor = yellow, visitedStroke = charcoal; 
  const nodeStroke = charcoal, nodeStrokeWidth = 1, linkStrokeWidth = 1, linkStrokeWidthHover = 6;

//simulation drag defs
const drag = (simulation) => {
  const dragstarted = (event, node) => {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    node.fx = node.x;
    node.fy = node.y;
  };

  const dragged = (event, node) => {
    node.fx = event.x;
    node.fy = event.y;
  };

  const dragended = (event, node) => {
    if (!event.active) simulation.alphaTarget(0);
    node.fx = node.x;
    node.fy = node.y;
   
  };

  return d3.drag()
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended);
};

  // Add the tooltip element to the graph
  const tooltip = document.querySelector("#graph-tooltip");
  if (!tooltip) {
    const tooltipDiv = document.createElement("div");
    tooltipDiv.classList.add(styles.tooltip);
    tooltipDiv.style.opacity = "0";
    tooltipDiv.id = "graph-tooltip";
    document.body.appendChild(tooltipDiv);
  }
  const div = d3.select("#graph-tooltip");
  const addTooltip = (hoverTooltip, d, x, y) => {
    div
      .transition()
      .duration(200)
      .style("opacity", 1);
    div
      .html(hoverTooltip(d))
      .style("left", `${x-16}px`)
      .style("top", `${y-16}px`);
  };

  const removeTooltip = () => {
    div
      .transition()
      .duration(200)
      .style("opacity", 0);
  };

  const simulation = d3
    .forceSimulation(nodes)
    .force("link", d3.forceLink(links).id(d => d.id))
    .force("charge", d3.forceManyBody().strength(-100))
    // .force("center", d3.forceCenter(0, height/4))
    .force("x", d3.forceX())
    .force("y", d3.forceY());

var initialtransform = d3.zoomIdentity
  .translate(-150, 50)
  .scale(1.2)

function zoomed({transform}) {
  svg.attr("transform", transform);
}


  //svg defs
  const svg = d3
    .select(container)
    .call(d3.zoom()
    .extent([[0, 0], [width, height]])
    .scaleExtent([1 / 10, 20])
    .on("zoom", zoomed)
    )
    .append("svg:svg")
    .attr("viewBox", [-width / 2, -height / 3, width, height])

  const link = svg
    .append("g")
    .selectAll("line")
    .data(links)
    .join('line')
    // .attr('d', d => {linkGenerator(d).curve(d.curve)})
    .attr("stroke-width", linkStrokeWidth)
    .attr("stroke-linecap", "round")
    .attr("stroke", linkColor)

  const node = svg
    .append("g")
    .attr("stroke", nodeStroke)
    .attr("stroke-width", nodeStrokeWidth)
    .selectAll("rect")
    .data(nodes)
    .join("rect")
    .attr("width", d => d.size+10)
    .attr("height", d => d.size+10)
    .attr("rx", 50)
    .attr("fill", function(d) { 
      // console.log(d)
        if (d.id === "index") { return yellow } 
        if ((history) && history.includes(d.label)) { return visitedColor }
        else {
            // console.log(d)
          return nodeColor}
      })
      .attr("stroke", function(d) { 
        if ((history) && history.includes(d.label)) { return visitedStroke }
        else {
            // console.log(d)
          return nodeStroke;}
      })
    .attr("cursor", "grab")
    .on("mouseover", (d, singleNode) => {
      
      // console.log("d on mouseover", d)
      const connectedLinks = link.filter(singleLink => {
        return singleLink.source.id === singleNode.id || singleLink.target.id === singleNode.id;
      });

      link.attr("stroke", "rgba(0,0,0,0.1)");

      connectedLinks._groups[0].forEach(line => {
        //console.log(line.attributes);
        line.attributes.stroke.nodeValue = linkHoverColor;
        line.attributes[0].nodeValue = linkStrokeWidthHover;
      });


        addTooltip(nodeHoverTooltip, d, d.pageX, d.pageY);
    })
    .on("mouseout", (d, singleNode) => {

      //console.log("event", singleNode);
      const connectedLinks = link.filter(singleLink => {
        return singleLink.source.id === singleNode.id || singleLink.target.id === singleNode.id;
      });

      link.attr("stroke", "rgba(0,0,0,0)");

      connectedLinks._groups[0].forEach(line => {
        // console.log(line.attributes.stroke.nodeValue);
        line.attributes.stroke.nodeValue = linkColor;
        line.attributes[0].nodeValue = linkStrokeWidth;
      });

      
        removeTooltip();
      })

    .on('click', function(d, i) {
      // setTimeout(() => {
        window.location.href = "../../notes/" + i.id;
      // }, 1500);
       
      })
    .call(drag(simulation))

  const label = svg.append("g")
    .attr("class", "labels")
    .selectAll("text")
    .data(nodes)
    .enter()
    .append("text")
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .call(drag(simulation))



  simulation.on("tick", () => {
    //update link positions
    link
      .attr("x1", d => d.source.x)
      .attr("y1", d => d.source.y)
      .attr("x2", d => d.target.x)
      .attr("y2", d => d.target.y);

    // update node positions
    node
      .attr("x", d => d.x-10)
      .attr("y", d => d.y-10)

    // update label positions
    label
      .attr("x", d => { return d.x; })
      .attr("y", d => { return d.y; })
  });

  return {
    destroy: () => {
      simulation.stop();
    },
    nodes: () => {
      return svg.node();
    }
  };
}

