//Wrapper for useEffect to check when things in the Graph Overview change
import React, { useEffect } from "react";
import { RunForceGraph } from "./entanglementGenerator";
import styles from "./entanglement.module.css";

export function EntanglementGraph({ linksData, nodesData, nodeHoverTooltip}) {
  const containerRef = React.useRef(null);

  React.useEffect(() => {

    // console.log("history being passed:", history )

    let destroyFn;

    if (containerRef.current) {
      const { destroy } = RunForceGraph(containerRef.current, linksData, nodesData, nodeHoverTooltip);
      destroyFn = destroy;
    }

    return destroyFn;
  }, []);

  return <div ref={containerRef} className={styles.container} />;
}

// import React, { Component } from "react";
// import { RunForceGraph } from "./entanglementGenerator";
// import styles from "./entanglement.module.css";

// class EntanglementGraph extends Component {
//   constructor(props) {
//     super(props);
//     this.containerRef = React.createRef();
//   }

//   componentDidMount() {
//     this.runForceGraph();
//   }

//   componentDidUpdate() {
//     this.runForceGraph();
//   }

//   componentWillUnmount() {
//     if (this.destroyFn) {
//       this.destroyFn();
//     }
//   }

//   runForceGraph() {
//     const { linksData, nodesData, nodeHoverTooltip } = this.props;

//     if (this.containerRef.current) {
//       const { destroy } = RunForceGraph(this.containerRef.current, linksData, nodesData, nodeHoverTooltip);
//       this.destroyFn = destroy;
//     }
//   }

//   render() {
//     return <div ref={this.containerRef} className={styles.container} />;
//   }
// }

// export default EntanglementGraph;
